import React, { createContext, useState, useEffect } from "react";

export const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState(() => {
    const savedChats = sessionStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : {};
  });

  const addMessageToChat = (chatId, message) => {
    setChats((prevChats) => {
      const updatedChat = {
        ...prevChats[chatId],
        messages: [...(prevChats[chatId]?.messages || []), message],
      };
      const updatedChats = {
        ...prevChats,
        [chatId]: updatedChat,
      };
      sessionStorage.setItem("chats", JSON.stringify(updatedChats));

      return updatedChats;
    });
  };

  const updateChatTitle = (chatId, newTitle) => {
    setChats((prevChats) => {
      const updatedChat = {
        ...prevChats[chatId],
        title: newTitle,
      };
      const updatedChats = {
        ...prevChats,
        [chatId]: updatedChat,
      };
      sessionStorage.setItem("chats", JSON.stringify(updatedChats));

      return updatedChats;
    });
  };

  const updateChatMethod = (chatId, newMethod) => {
    setChats((prevChats) => {
      const updatedChat = {
        ...prevChats[chatId],
        method: newMethod,
      };
      const updatedChats = {
        ...prevChats,
        [chatId]: updatedChat,
      };
      sessionStorage.setItem("chats", JSON.stringify(updatedChats));

      return updatedChats;
    });
  };

  const deleteChat = (chatId) => {
    setChats((prevChats) => {
      const { [chatId]: _, ...remainingChats } = prevChats; // Remove the specific chat
      sessionStorage.setItem("chats", JSON.stringify(remainingChats));

      return remainingChats;
    });
  };

  const replaceLastMessageInChat = (chatId) => {
    setChats((prevChats) => {
      const currentMessages = prevChats[chatId]?.messages || [];
      const updatedChat = {
        ...prevChats[chatId],
        messages: currentMessages.length > 0
          ? [...currentMessages.slice(0, -2)] // Replace the last message
          : [], // If there are no messages, just add the new one
      };
      const updatedChats = {
        ...prevChats,
        [chatId]: updatedChat,
      };
      sessionStorage.setItem("chats", JSON.stringify(updatedChats));

      return updatedChats;
    });
  };

  useEffect(() => {
    sessionStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  return (
    <ChatContext.Provider
      value={{
        chats,
        addMessageToChat,
        updateChatTitle,
        updateChatMethod,
        deleteChat,
        replaceLastMessageInChat, // Add this to the context
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
