import React, { useContext, useState } from 'react';
import "./Home.css";
import { ChatContext } from '../context/ChatContext';
import { useNavigate } from 'react-router-dom';
import Logo from "../assets/Looqup_White.svg"; // Make sure this path is correct

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        padding: '20px',
    },
    button: {
        padding: '10px 30px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: '#aba1be',
        border: 'none',
        borderRadius: '40px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
    },
    buttonHover: {
        backgroundColor: 'blue',
    },
    welcomeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
    },
    welcomeText: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginRight: '10px',
    },
};

const Home = () => {
    const navigate = useNavigate();
    const { updateChatMethod } = useContext(ChatContext);

    const [isHoveredDerma, setIsHoveredDerma] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const startNewChat = (value) => {
        const chatId = Date.now();
        updateChatMethod(chatId, value);
        navigate(`/${chatId}`);
    };

    return (
        <div className='flex justify-center flex-col flex-wrap' style={{ height: "80vh", alignContent: "center" }}>
            <div style={styles.welcomeContainer}>
                <span style={styles.welcomeText} className="mainText">Welcome to</span>
                <img
                    src={Logo}
                    alt="Looqup"
                    width={200}
                    height={67}
                    className="gradientText"
                />
            </div>
            <p className='text-center text-2xl font-medium text-white '>
                Welcome to the MSL Co-Pilot. I am trained on publicly available data sources such as PubMed, Clinical Trials and FDA approved product datasheets.
            </p>
            <p className='text-center pt-4 text-2xl font-medium text-white'>I am a Medical Science Liaison for:</p>
            <div style={styles.container}>
                <div
                    style={isHoveredDerma ? { ...styles.button, ...styles.buttonHover } : styles.button}
                    onMouseEnter={() => setIsHoveredDerma(true)}
                    onMouseLeave={() => setIsHoveredDerma(false)}
                    onClick={() => startNewChat('Dermatology')}
                >
                    Dermatology
                </div>
                <div
                    style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => startNewChat('Oncology')}
                >
                    Oncology
                </div>
            </div>
        </div>
    );
};

export default Home;