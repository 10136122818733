import React from "react";
import MarkdownIt from "markdown-it";
import markdownItAbbr from "markdown-it-abbr";
import markdownItCheckbox from "markdown-it-checkbox";
import markdownItDeflist from "markdown-it-deflist";
import markdownItFootnote from "markdown-it-footnote";
import markdownItIns from "markdown-it-ins";
import markdownItMark from "markdown-it-mark";
import markdownItMath from "markdown-it-math";
import markdownItSub from "markdown-it-sub";
import markdownItSup from "markdown-it-sup";
import markdownItTexmath from "markdown-it-texmath";
import markdownItToc from "markdown-it-toc";

import "./Markdown.css";
const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
})
  .use(markdownItAbbr)
  .use(markdownItCheckbox)
  .use(markdownItDeflist)
  .use(markdownItFootnote)
  .use(markdownItIns)
  .use(markdownItMark)
  .use(markdownItMath)
  .use(markdownItSub)
  .use(markdownItSup)
  .use(markdownItTexmath, {
    engine: require("katex"),
    delimiters: "dollars",
  })
  .use(markdownItToc, {
    includeLevel: [1, 2, 3],
  });

const MarkdownData = ({ msg }) => {
  const renderedMarkdown = md.render(msg.text || "No content available");

  return (
    <div className="markdown-editor">
      <div
        className="markdown-preview text-base text-white text-lg font-normal"
        dangerouslySetInnerHTML={{ __html: md.render(renderedMarkdown) }}
      />
    </div>
  );
};

export default MarkdownData;
