import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from "../assets/White-icon 1.png"; // Use your actual logo path here
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Menu, Dropdown, Select } from 'antd'; // Using Ant Design for the dropdown
import { DownOutlined } from '@ant-design/icons'; // Ant Design icon for dropdown
import { ChatContext } from '../context/ChatContext';
import "./Navbar.css";
import DropdownArrow from "../assets/DropDownArrow.png"

const { Option } = Select;

const styles = {
    container: {
        position: 'relative',
        width: '14rem',
        display: "flex",
        flexDirection: "row"
    },
    select: {
        width: "12rem",
        padding: '.8rem 2rem .8rem .8rem ',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: "transparent",
        border: 'none',

        borderRadius: '4px',
        appearance: 'none',
        outline: 'none',
    },
    option: {
    },
    icon: {
        position: 'absolute',
        top: '50%',
        right: '-1rem',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
    },
};

const Navbar = () => {

    const [ShowMethod, setShowMethod] = useState(false);
    const [id, setId] = useState('');
    const { chats } = useContext(ChatContext);
    useEffect(() => {
        const Id = getChatIdFromUrl();
        if (Id) {
            if (chats[Id] && chats[Id].method) { setShowMethod(true) }
            setId(Id);
        } else {
            setShowMethod(false);
            setId(null);
        }
    }
        , [chats]);
    const navigate = useNavigate();
    function getChatIdFromUrl() {
        const path = window.location.pathname;
        console.log("Current Path:", path);

        // List of known routes that are not chat IDs
        const knownRoutes = ['/account-settings', '/lookup-history'];

        // Check if path matches any of the known routes
        if (knownRoutes.includes(path)) {
            console.error("Route is known and not a chat ID.");
            return null;
        }

        // Extract potential chat ID from the URL
        const parts = path.split('/').filter(Boolean);
        console.log("Path Parts:", parts);

        // Assume the chat ID is the last part of the URL and validate
        const lastPart = parts[parts.length - 1];
        if (/^\d+$/.test(lastPart)) {
            return lastPart;
        } else {
            console.error("Invalid route or Chat ID not found.");
            return null;
        }
    }

    const { updateChatMethod } = useContext(ChatContext);

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/account-settings">Account Settings</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/lookup-history">Lookup History</Link>
            </Menu.Item>
        </Menu>
    );
    const data = (
        <Menu>
            <Menu.Item onClick={() => startNewChat("dermatology")}>
                <p>Dermatology</p>
            </Menu.Item>
            <Menu.Item onClick={() => startNewChat("oncoLogy")}>
                <p>OncoLogy</p>
            </Menu.Item>
        </Menu>
    );

    const startNewChat = (value) => {
        const chatId = Date.now();
        updateChatMethod(chatId, value);
        navigate(`/${chatId}`);
        setShowMethod(false);
    };

    return (
        <div className="navbar flex justify-between items-center border-b-2 h-16 z-20 px-4">
            {/* Logo Section */}
            <div className="logo w-10 h-10 flex justify-center">
                <Link to="/">
                    <img src={Logo} alt="Logo" />
                </Link>
            </div>

            {/* Center Section with Dropdown */}
            <div className="center-section text-white text-lg">
                <div className="cursor-pointer flex flex-row items-center space-x-2">
                    <div style={styles.container}>
                        {ShowMethod ? <div> {chats[id].method.charAt(0).toUpperCase()}{chats[id].method.slice(1)}</div> : "Select the Speciality"}
                        <Dropdown overlay={data} trigger={['click']}>
                            <div className="cursor-pointer flex items-center space-x-2">
                                <div >
                                    {/* Insert your SVG here */}
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_bd_761_919)">
                                            <rect x="2.43262" y="0.300781" width="20" height="20" rx="10" fill="url(#paint0_linear_761_919)" shape-rendering="crispEdges" />
                                        </g>
                                        <g clip-path="url(#clip0_761_919)">
                                            <path d="M8.19809 8.24866L12.2046 12.2552C12.2339 12.2864 12.2692 12.3112 12.3084 12.3282C12.3476 12.3451 12.3899 12.3539 12.4326 12.3539C12.4754 12.3539 12.5177 12.3451 12.5569 12.3282C12.5961 12.3112 12.6314 12.2864 12.6607 12.2552L16.6672 8.24866" stroke="#F2F2F2" stroke-width="0.81759" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_bd_761_919" x="-7.24876" y="-9.38059" width="39.3627" height="39.3627" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.84069" />
                                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_761_919" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="1.53673" />
                                                <feGaussianBlur stdDeviation="0.768363" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="effect1_backgroundBlur_761_919" result="effect2_dropShadow_761_919" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_761_919" result="shape" />
                                            </filter>
                                            <linearGradient id="paint0_linear_761_919" x1="12.4326" y1="0.300781" x2="12.4326" y2="20.3008" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="white" stop-opacity="0.4" />
                                                <stop offset="1" stop-color="white" stop-opacity="0.1" />
                                            </linearGradient>
                                            <clipPath id="clip0_761_919">
                                                <rect width="9.12058" height="9.12058" fill="white" transform="translate(7.87238 5.74048)" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                            </div>
                        </Dropdown>

                    </div>
                </div>
            </div>

            {/* Profile Section */}
            <div className="flex items-center">
                <Dropdown overlay={menu} trigger={['click']}>
                    <div className="cursor-pointer flex items-center space-x-2">
                        <img
                            className="avatar w-10 h-10 rounded-full"
                            src="https://via.placeholder.com/40" // Use an actual avatar source
                            alt="User Avatar"
                        />
                        <div >
                            {/* Insert your SVG here */}
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_bd_761_919)">
                                    <rect x="2.43262" y="0.300781" width="20" height="20" rx="10" fill="url(#paint0_linear_761_919)" shape-rendering="crispEdges" />
                                </g>
                                <g clip-path="url(#clip0_761_919)">
                                    <path d="M8.19809 8.24866L12.2046 12.2552C12.2339 12.2864 12.2692 12.3112 12.3084 12.3282C12.3476 12.3451 12.3899 12.3539 12.4326 12.3539C12.4754 12.3539 12.5177 12.3451 12.5569 12.3282C12.5961 12.3112 12.6314 12.2864 12.6607 12.2552L16.6672 8.24866" stroke="#F2F2F2" stroke-width="0.81759" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <filter id="filter0_bd_761_919" x="-7.24876" y="-9.38059" width="39.3627" height="39.3627" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.84069" />
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_761_919" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="1.53673" />
                                        <feGaussianBlur stdDeviation="0.768363" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="effect1_backgroundBlur_761_919" result="effect2_dropShadow_761_919" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_761_919" result="shape" />
                                    </filter>
                                    <linearGradient id="paint0_linear_761_919" x1="12.4326" y1="0.300781" x2="12.4326" y2="20.3008" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0.4" />
                                        <stop offset="1" stop-color="white" stop-opacity="0.1" />
                                    </linearGradient>
                                    <clipPath id="clip0_761_919">
                                        <rect width="9.12058" height="9.12058" fill="white" transform="translate(7.87238 5.74048)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

export default Navbar;
