import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ChatContext } from "../context/ChatContext.jsx";
import axios from "axios";
import moment from "moment";
import Logo from "../assets/White-icon 1.png";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { Popover, Spin } from "antd";
//import Logo from "../assets/image.png";
import { replaceProfanities } from "no-profanity";
import TextArea from "antd/es/input/TextArea.js";
import Thinking from "./Thinking.jsx";
import MarkdownData from "./Markdown.jsx";
import MainLogo from "../assets/Looqup_White.svg";
import "./chatId.css";
import { BsLayoutTextSidebarReverse, BsLayoutTextSidebar } from "react-icons/bs";

import Suggest from "../assets/image.png";
import {
    FaShareAlt,
    FaSync,
    FaThumbsDown,
    FaEdit,
    FaCopy,
} from "react-icons/fa";
import { RiHistoryFill } from "react-icons/ri";

const templates = [
    "Compare Rinvoq to Dupixent for an HCP dialog",
    "Research latest research on Atopic Dermatitis",
    "Review Rinvoq’s contraindications",
    "Research latest conference papers on Atopic Dermatitis",
    "Review Dr Guttman’s research interests",
    "Understand Dupixent’s side effects"
];

const CustomPopup = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <p className="text-lg mb-4">{message}</p>
                <button
                    onClick={onClose}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

const ChatID = () => {
    const { chatId } = useParams();

    const { chats, addMessageToChat, replaceLastMessageInChat } =
        useContext(ChatContext);
    const [chat, setChat] = useState({});
    const [isHovered, setisHovered] = useState(false)
    const chatHistoryId = Object.keys(chat);
    const [Input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState({
        response: {
            questions: [],
        },
    });
    const [thinking, setThinking] = useState(false);
    const [logs, setLogs] = useState([]);
    const [source, setSource] = useState([]);
    const [wordCount, setWordCount] = useState(0);
    const [answer, setAnswer] = useState("");
    const [isRegenerate, setIsRegenerate] = useState(false);
    const [latestAnswer, setLatestAnswer] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const [showHistory, setShowHistory] = useState(true);
    const [showAnswerState, setShowAnswerState] = useState({});

    const toggleAnswer = (index) => {
        setShowAnswerState((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    useEffect(() => {
        const messages = chats?.[chatId]?.messages;

        if (messages?.length) {
            const latestMessageIndex = messages.length - 1;

            // Check if the latest message is from a non-user (e.g., bot) and automatically show its answer
            if (messages[latestMessageIndex].sender !== 'user') {
                setShowAnswerState((prevState) => ({
                    ...prevState,
                    [latestMessageIndex]: true, // Automatically show the answer for the new message
                }));
            }
        }
    }, [chats, chatId]);

    const toggleHistory = () => {
        setShowHistory(prev => !prev);
    };

    const messagesEndRef = useRef();
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        setSuggestions({
            response: {
                questions: [],
            },
        });
    }, [chatId]);

    useEffect(() => {
        scrollToBottom();
    }, [chats, thinking]);

    useEffect(() => {
        setChat(chats);
    }, [chats]);

    const handleSend = async () => {
        if (Input.trim() === "") return;
        setWordCount("0");
        setSource([]);
        const cleanedPrompt = replaceProfanities(Input);
        const message = cleanedPrompt;
        addMessageToChat(chatId, { sender: "user", text: Input });

        setSuggestions({
            response: {
                questions: [],
            },
        });

        let isJobCompleted = false;
        let elapsedTime = 0;
        let intervalId = null;
        // Add user's message to chat

        try {
            const data = await fetchQuestionRespFromApi();
            const updatedData = {
                ...data,
                medical_speciality: chats[chatId].method,
            };
            const job = await fetchingJobRespFromApi({ updatedData });

            intervalId = setInterval(async () => {
                if (!isJobCompleted) {
                    try {
                        const res = await axios.get(
                            `https://pharma.looquplab.com/api/process_query/logs/${job}`
                        );
                        const data = res.data;
                        setLogs(data.logs);

                        if (
                            data.logs[data.logs.length - 1].includes(
                                "Job completed successfully"
                            )
                        ) {
                            isJobCompleted = true;
                            clearInterval(intervalId);

                            try {
                                const res = await axios.get(
                                    `https://pharma.looquplab.com/api/process_query/results/${job}`
                                );
                                const resultData = res.data;
                                addMessageToChat(chatId, {
                                    sender: "bot",
                                    text: resultData.result,
                                });
                                setAnswer(resultData.result);

                                fetchSuggestions(message);
                                setInput("")
                                setThinking(false);
                                setIsRegenerate(false);
                                isJobCompleted = false;
                            } catch (error) {
                                alert("Something went wrong. Please try again later.");
                                setAnswer(error.message);
                                setIsRegenerate(false);
                            }
                        }
                    } catch (error) {
                        alert("Something went wrong. Please try again later.");

                        setAnswer(error.message);
                        console.error("Error fetching data", error);
                        clearInterval(intervalId);

                        setIsRegenerate(false);
                    }

                    elapsedTime += 2; // Increment elapsed time by 2 seconds
                } else {
                    clearInterval(intervalId); // Stop polling after 60 seconds
                }
            }, 2000); // Poll every 2 seconds
        } catch (error) {
            console.error("Error during initial API calls", error);
        }
    };

    const fetchSuggestions = async (newMsg) => {
        try {
            const result = await axios.post(
                `https://pharma.looquplab.com/api/candidate_questions`,
                {
                    question_history: [newMsg],
                }
            );
            setSuggestions(result.data);
        } catch (error) {
            console.log(`Error: ${error.message} Please try again later.`);
        }
    };

    const fetchQuestionRespFromApi = async () => {
        const cleanedPrompt = replaceProfanities(Input);
        setLogs([]);
        const message = cleanedPrompt;
        const url = `https://pharma.looquplab.com/api/question_output_mapping`;
        const payload = { question: message };
        try {
            setThinking(true);
            const response = await axios.post(url, payload);
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const fetchingJobRespFromApi = async ({ updatedData }) => {
        const url = `https://pharma.looquplab.com/api/process_query`;
        const payload = {
            context: updatedData.context,
            format: updatedData.format,
            query: updatedData.query,
            medical_speciality: updatedData.medical_speciality,
        };

        try {
            const { data } = await axios.post(url, payload, {
                headers: { "Content-Type": "application/json" },
            });
            setThinking(true);
            return data.job_id;
        } catch (error) {

            return null;
        }
    };
    const handleInputChange = (e) => {
        const text = e.target.value;
        setInput(text);
    };
    const SourcesData = (data) => {
        console.log(data);

        // Using the functional form of setSource to access previous state
        setSource((prevSource) => {
            // Check if data already exists
            const dataExists = prevSource.some((item) => item.id === data.id); // Modify this condition based on your data structure

            // If data does not exist, add it to the state
            if (!dataExists) {
                return [...prevSource, data];
            }

            // Return the previous state if data exists
            return prevSource;
        });
        console.log(source);
    };

    useEffect(() => {
        if (chats[chatId]?.messages) {
            const botMessages = chats[chatId].messages.filter(msg => msg.sender !== "user");
            if (botMessages.length > 0) {
                setLatestAnswer(botMessages[botMessages.length - 1].text);
            }
        }
    }, [chats, chatId]);

    const CopyAnswer = () => {
        navigator.clipboard.writeText(latestAnswer).then(() => {
            setPopupMessage("Latest answer copied to clipboard!");
            // setShowPopup(true);
        });
    };

    const RegenerateData = async () => {
        if (chats[chatId]?.messages) {
            const userMessages = chats[chatId].messages.filter(msg => msg.sender === "user");
            if (userMessages.length > 0) {
                const lastUserMessage = userMessages[userMessages.length - 1].text;
                setInput(lastUserMessage);
                setTimeout(async () => {
                    await handleSend();
                }, 0);
            }
        }
    };


    const EditLookUp = () => { };
    const formatDate = (timestamp) => {
        const date = new Date(parseInt(timestamp));

        const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
        const dateOptions = { day: "numeric", month: "long" };

        const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
            date
        );
        const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
            date
        );

        return `${formattedTime}, ${formattedDate}`;
    };

    return (
        <div>
            {chats[chatId].messages && chats[chatId].messages.length > 0 ? (
                <div className="flex flex-row" style={{ height: "80vh" }}>
                    <div className={`${showHistory ? 'w-1/6' : 'w-0'
                        } transition-width duration-300 ease-in-out p-4 flex flex-col overflow-hidden`}
                    >
                        <div className="flex px-4 pt-4 flex-row items-center">
                            <div className="w-10 h-10 text-white">
                                <RiHistoryFill size={40} /> {/* Adjust size if needed */}
                            </div>
                            <p className="text-4xl text-white pl-2 font-semibold">History</p>
                        </div>
                        {chatHistoryId.length === 0 ? (
                            <p className="hover:underline mr-2 text-gray-950 text-xl overflow-hidden  flex-grow  text-ellipsis whitespace-nowrap">No previous chats</p>
                        ) : (
                            <ul className="h-dvh overflow-auto">
                                {Object.entries(chat)
                                    .reverse()
                                    .map(([chatId, chatData]) => {
                                        if (chatData.messages && chatData.messages.length > 0) {
                                            return (
                                                <div>
                                                    <li
                                                        key={chatId}
                                                        className="mb-2 pt-5 flex items-center"
                                                    >
                                                        <svg
                                                            width="30"
                                                            height="20"
                                                            viewBox="0 0 17 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="mr-2 flex-shrink-0"
                                                        >
                                                            <path
                                                                d="M15.375 0.75H1.62502C1.2935 0.75 0.975559 0.881696 0.741138 1.11612C0.506718 1.35054 0.375022 1.66848 0.375022 2V14.5C0.373609 14.7383 0.440964 14.9719 0.569006 15.1728C0.697049 15.3737 0.880341 15.5335 1.0969 15.6328C1.26238 15.7096 1.44257 15.7496 1.62502 15.75C1.91846 15.7493 2.20217 15.6447 2.4258 15.4547C2.42953 15.4523 2.43294 15.4494 2.43596 15.4461L4.94533 13.25H15.375C15.7065 13.25 16.0245 13.1183 16.2589 12.8839C16.4933 12.6495 16.625 12.3315 16.625 12V2C16.625 1.66848 16.4933 1.35054 16.2589 1.11612C16.0245 0.881696 15.7065 0.75 15.375 0.75ZM15.375 12H4.94533C4.65098 11.9999 4.36601 12.1036 4.14065 12.293L4.13127 12.3016L1.62502 14.5V2H15.375V12ZM5.37502 5.75C5.37502 5.58424 5.44087 5.42527 5.55808 5.30806C5.67529 5.19085 5.83426 5.125 6.00002 5.125H11C11.1658 5.125 11.3248 5.19085 11.442 5.30806C11.5592 5.42527 11.625 5.58424 11.625 5.75C11.625 5.91576 11.5592 6.07473 11.442 6.19194C11.3248 6.30915 11.1658 6.375 11 6.375H6.00002C5.83426 6.375 5.67529 6.30915 5.55808 6.19194C5.44087 6.07473 5.37502 5.91576 5.37502 5.75ZM5.37502 8.25C5.37502 8.08424 5.44087 7.92527 5.55808 7.80806C5.67529 7.69085 5.83426 7.625 6.00002 7.625H11C11.1658 7.625 11.3248 7.69085 11.442 7.80806C11.5592 7.92527 11.625 8.08424 11.625 8.25C11.625 8.41576 11.5592 8.57473 11.442 8.69194C11.3248 8.80915 11.1658 8.875 11 8.875H6.00002C5.83426 8.875 5.67529 8.80915 5.55808 8.69194C5.44087 8.57473 5.37502 8.41576 5.37502 8.25Z"
                                                                fill="#4B4D54"
                                                            />
                                                        </svg>
                                                        <Link
                                                            to={`/${chatId}`}
                                                            className="hover:underline mr-2 text-gray-500 text-xl overflow-hidden whitespace-nowrap text-ellipsis flex-grow"
                                                        >
                                                            <Popover
                                                                content={chatData.messages[0].text}
                                                                className="text-ellipsis whitespace-nowrap"
                                                            >
                                                                {chatData.messages[0].text}
                                                            </Popover>
                                                        </Link>
                                                    </li>
                                                    <div className="flex pt-4 border-b"></div>
                                                </div>
                                            );
                                        }
                                    })}
                            </ul>
                        )}

                    </div>
                    <div className="w-10 text-white pt-10" onClick={toggleHistory}>
                        {showHistory ? <BsLayoutTextSidebar /> : <BsLayoutTextSidebarReverse />}
                    </div>
                    <div className={`pt-2 flex flex-col justify-between ${showHistory ? 'w-5/6' : 'w-full'
                        } transition-width duration-300 ease-in-out  overflow `}>
                        <div
                            className="flex flex-row   overflow-y-auto "
                            style={{
                                height: "100dvh",
                            }}
                        >
                            <div className="   overflow-y-auto rounded-lg w-11/12 ">
                                <div>
                                    {chats[chatId].messages.map((msg, index) => (
                                        <div
                                            key={index}
                                            className={`my-2 ${msg.sender === "user" ? "ml-2" : "ml-4"}`}
                                        >
                                            <p className="flex  items-start align-middle space-x-4 mb-4">
                                                {msg.sender === "user" ? (
                                                    <div className=" flex ">
                                                        <p className=" p-2 text-4xl font-bold gradientText">
                                                            <div>{msg.text}</div>
                                                        </p>
                                                    </div>
                                                ) : isRegenerate ? (
                                                    <></>
                                                ) : (
                                                    <div className="flex flex-col">
                                                        <div className=" flex  flex-row  ">
                                                            <div className="flex flex-row">
                                                                <img src={Logo} alt="" />
                                                                <p
                                                                    onClick={() => toggleAnswer(index)}
                                                                    className="text-4xl pl-2 text-white font-semibold"
                                                                >
                                                                    {" "}
                                                                    Answer
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className=" flex ">
                                                            <div>
                                                                {showAnswerState[index] && (
                                                                    <div className=" w-4/5">
                                                                        <MarkdownData msg={msg} />
                                                                        <div ref={messagesEndRef} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>

                                {thinking &&
                                    logs.map((log, index) => (
                                        <div className="px-4 w-4/5">
                                            <Thinking
                                                key={index}
                                                log={log}
                                                SourcesData={SourcesData}
                                                messagesEndRef={messagesEndRef}
                                            />
                                        </div>
                                    ))}
                                {suggestions.response.length > 0 && (
                                    <div className="w-4/5 px-4">
                                        <div className="flex flex-row">
                                            <img src={Suggest} className="w-10 h-10" alt="" />
                                            <p className="text-4xl pl-2 text-white font-semibold">
                                                Suggestions
                                            </p>
                                        </div>
                                        <div>
                                            {suggestions.response.map((item, index) => (
                                                <>
                                                    <div
                                                        onClick={() => setInput(item)}
                                                        className="px-5 py-2.5 text-start flex flex-col "
                                                    >
                                                        <p className="text-white text-xl font-normal hover:text-blue-500">
                                                            {" "}
                                                            {item}
                                                        </p>
                                                        <div className="w-full border-b-2 border-cyan-50"></div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="w-1/12">
                                <p className="gradientText  font-semibold p-3 px-1 pt-2 ">
                                    {formatDate(chatId)}
                                </p>
                                <div className="  flex flex-col items-end  overflow-hidden justify-around bg-gradient-to-b from-purple-600 max-w-24 min-w-8 to-indigo-800 text-white rounded-xl py-6 w-full  shadow-lg">
                                    <div className="flex flex-col items-center space-y-8">
                                        {/* Share Button */}
                                        <button className="flex flex-col items-center hover:text-indigo-300 transition">
                                            <FaShareAlt className="text-xl" />
                                            <span className="mt-1 text-sm">Share With Team</span>
                                        </button>

                                        {/* Regenerate Button */}
                                        <button
                                            onClick={RegenerateData}
                                            className="flex flex-col items-center hover:text-indigo-300 transition"
                                        >
                                            <FaSync className="text-xl" />
                                            <span className="mt-1 text-sm">Regenerate Looqup</span>
                                        </button>

                                        {/* Bad Answer Button */}
                                        <button className="flex flex-col items-center hover:text-indigo-300 transition">
                                            <FaThumbsDown className="text-xl" />
                                            <span className="mt-1 text-sm">Bad Answer</span>
                                        </button>

                                        {/* Edit Button */}
                                        <button
                                            onClick={EditLookUp}
                                            className="flex flex-col items-center hover:text-indigo-300 transition"
                                        >
                                            <FaEdit className="text-xl" />
                                            <span className="mt-1 text-sm">Edit Looqup</span>
                                        </button>

                                        {/* Copy Button */}
                                        <button
                                            onClick={CopyAnswer}
                                            className="flex flex-col items-center hover:text-indigo-300 transition"
                                        >
                                            <FaCopy className="text-xl" />
                                            <span className="mt-1 text-sm">Copy Answer</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col align-middle mb-4 mx-auto w-full px-2 ">
                            <div className="relative ">
                                <TextArea
                                    value={Input}
                                    onChange={handleInputChange}
                                    className="border border-gray-300 text-white text-2xl font-medium rounded-lg block w-full p-2.5 pr-24 resize-none h-16  bg-slate-400 hover:bg-slate-400"
                                    style={{
                                        backgroundColor: isHovered ? '#3d0365' : '#3d0365'
                                      }}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 5,
                                    }}
                                />
                                {thinking ? (
                                    <button
                                        type="button"
                                        disabled
                                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                                    >
                                        <Spin className="w-5 h-5" />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={handleSend}
                                        className="absolute inset-y-0 right-0 flex items-end pr-3"
                                    >
                                        <svg
                                            width="28"
                                            height="29"
                                            viewBox="0 0 28 29"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M21.4898 13.3008L8.36484 5.80938C8.1436 5.68527 7.88983 5.63142 7.63726 5.65496C7.38468 5.67851 7.14525 5.77835 6.95076 5.94121C6.75628 6.10407 6.61594 6.32226 6.54841 6.56677C6.48087 6.81129 6.48933 7.07056 6.57265 7.31016L8.99452 14.3781C8.99421 14.3807 8.99421 14.3833 8.99452 14.3859C8.99409 14.3885 8.99409 14.3912 8.99452 14.3938L6.57265 21.4773C6.50592 21.6658 6.48538 21.8676 6.51274 22.0656C6.5401 22.2637 6.61458 22.4523 6.72991 22.6156C6.84525 22.7789 6.99808 22.9122 7.17557 23.0042C7.35307 23.0963 7.55005 23.1444 7.74999 23.1445C7.96692 23.144 8.18004 23.0875 8.36874 22.9805L21.4867 15.4766C21.6802 15.3682 21.8414 15.2102 21.9537 15.019C22.066 14.8277 22.1254 14.61 22.1258 14.3883C22.1262 14.1665 22.0676 13.9486 21.956 13.7569C21.8443 13.5653 21.6837 13.4067 21.4906 13.2977L21.4898 13.3008ZM7.74999 21.8945V21.8875L10.1047 15.0195H14.625C14.7908 15.0195 14.9497 14.9537 15.0669 14.8365C15.1841 14.7193 15.25 14.5603 15.25 14.3945C15.25 14.2288 15.1841 14.0698 15.0669 13.9526C14.9497 13.8354 14.7908 13.7695 14.625 13.7695H10.1109L7.75468 6.90391L7.74999 6.89454L20.875 14.3813L7.74999 21.8945Z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div
                        className=" flex flex-col justify-between w-full  overflow "
                        style={{ height: "80vh" }}
                    >
                        <div
                            className="flex flex-col justify-center  item-center flex-grow">
                            <div className="text-center">
                            <div className="mb-8 md:mb-10">
                            <img
                                src={MainLogo}
                                alt="Looqup"
                                width={300}
                                height={100}
                                className="gradientText mx-auto" // Added mx-auto to center the image
                            />
                            </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 max-w-4xl mx-auto w-full mb-4 ">
                                    {templates.map((item, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            onClick={() => setInput(item)}
                                            className="px-6 py-4 text-xl font-bold text-white bg-[#aba1be] rounded-xl transition-all duration-300 ease-in-out w-full hover:bg-blue-500 shadow-lg"
            >
                                            {item}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col mb-4 align-middle mx-auto w-full px-2 ">
                            <div className="relative ">
                                <TextArea
                                    value={Input}
                                    onChange={handleInputChange}
                                    className="border border-gray-300 text-white text-2xl font-medium rounded-lg block w-full p-2.5 pr-24 resize-none h-16  bg-slate-400 hover:bg-slate-400"
                                    style={{
                                        backgroundColor: isHovered ? '#3d0365' : '#3d0365'
                                      }}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 8,
                                    }}
                                   
                                />
                                {thinking ? (
                                    <button
                                        type="button"
                                        disabled
                                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                                    >
                                        <Spin className="w-5 h-5" />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={handleSend}
                                        className="absolute inset-y-0 right-0 flex items-end pr-3"
                                    >
                                        <svg
                                            width="28"
                                            height="29"
                                            viewBox="0 0 28 29"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M21.4898 13.3008L8.36484 5.80938C8.1436 5.68527 7.88983 5.63142 7.63726 5.65496C7.38468 5.67851 7.14525 5.77835 6.95076 5.94121C6.75628 6.10407 6.61594 6.32226 6.54841 6.56677C6.48087 6.81129 6.48933 7.07056 6.57265 7.31016L8.99452 14.3781C8.99421 14.3807 8.99421 14.3833 8.99452 14.3859C8.99409 14.3885 8.99409 14.3912 8.99452 14.3938L6.57265 21.4773C6.50592 21.6658 6.48538 21.8676 6.51274 22.0656C6.5401 22.2637 6.61458 22.4523 6.72991 22.6156C6.84525 22.7789 6.99808 22.9122 7.17557 23.0042C7.35307 23.0963 7.55005 23.1444 7.74999 23.1445C7.96692 23.144 8.18004 23.0875 8.36874 22.9805L21.4867 15.4766C21.6802 15.3682 21.8414 15.2102 21.9537 15.019C22.066 14.8277 22.1254 14.61 22.1258 14.3883C22.1262 14.1665 22.0676 13.9486 21.956 13.7569C21.8443 13.5653 21.6837 13.4067 21.4906 13.2977L21.4898 13.3008ZM7.74999 21.8945V21.8875L10.1047 15.0195H14.625C14.7908 15.0195 14.9497 14.9537 15.0669 14.8365C15.1841 14.7193 15.25 14.5603 15.25 14.3945C15.25 14.2288 15.1841 14.0698 15.0669 13.9526C14.9497 13.8354 14.7908 13.7695 14.625 13.7695H10.1109L7.75468 6.90391L7.74999 6.89454L20.875 14.3813L7.74999 21.8945Z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div ref={messagesEndRef} />

            {showPopup && (
                <CustomPopup
                    message={popupMessage}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
};

export default ChatID;
