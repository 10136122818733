import React, { useState, useEffect, useContext } from 'react';
import "./chatId.css";
import { ChatContext } from '../context/ChatContext';
import { Link } from 'react-router-dom';

const LooqupHistory = ({ }) => {
    const { chats } = useContext(ChatContext);
    const [chat, setChat] = useState({});

    useEffect(() => {
        setChat(chats);
    }, [chats]);

    const chatIds = Object.keys(chat);

    const formatDate = (timestamp) => {
        const date = new Date(parseInt(timestamp));

        // Format options for date and time
        const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
        const timeZone = 'America/New_York'; // Use the desired time zone

        // Format the date and time
        const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
        const formattedTime = new Intl.DateTimeFormat('en-US', { ...timeOptions, timeZone }).format(date);

        // Append the time zone abbreviation manually
        const timeZoneAbbreviation = 'EST'; // Change this based on your time zone

        return `${formattedDate} ${formattedTime} ${timeZoneAbbreviation}`;
    };



    return (
        <div>
            <div className='flex flex-col'>
                <p className='gradientText p-8 text-7xl font-bold'>
                    Looqup History
                </p>

                <div className="relative overflow-x-auto shadow-md sm:rounded-xl px-10">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-white border-b-2 font-light ">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-2xl">
                                    Role
                                </th>
                                <th scope="col" className="px-6 py-3  text-2xl">
                                    Inquiry
                                </th>
                                <th scope="col" className="px-6 py-3 text-2xl">
                                    TimeStamp
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {chatIds.length > 0 ? (
                                chatIds.reverse().map(chatId => {
                                    const chatData = chat[chatId];
                                    if (chatData.messages && chatData.messages.length > 0) {
                                        const firstMessage = chatData.messages[0];
                                        return (
                                            <tr className="border-b-2" key={chatId}>
                                                <td className="px-6 py-4 text-xl font-medium whitespace-nowrap dark:text-white">
                                                    <Link to={`/${chatId}`}>{chatData.method}</Link>

                                                </td>
                                                <td className="px-6 py-4 text-xl whitespace-nowrap dark:text-white">
                                                    {firstMessage.text}
                                                </td>
                                                <td className="px-6 py-4 text-xl whitespace-nowrap dark:text-white">
                                                    {formatDate(chatId)}
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr key={chatId}>
                                                <td colSpan="4" className="px-6 py-4 text-center">
                                                    No messages available
                                                </td>
                                            </tr>
                                        );
                                    }
                                })
                            ) : (
                                <tr >
                                    <td colSpan="4" className="px-6 py-4 text-center">
                                        No previous chats
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default LooqupHistory;
