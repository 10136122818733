import React, { lazy, Suspense } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ChatProvider from "./context/ChatContext.jsx";
import Navbar from "./Components/Navbar.jsx";
import Home from "./Components/Home.jsx";
import ChatID from "./Components/ChatId.jsx";
import LooqupHistory from "./Components/LooqupHistory.jsx";
import AccountSettings from "./Components/AccountSettings.jsx";
function App() {
  return (
    <ChatProvider>
      <Router>
        <div style={{ backgroundImage: "linear-gradient(135deg,black, #9900FF)", height: "100vh", alignItems: "center", justifyContent: "center" }} className="flex " >
          <div className="border-2 rounded-lg border-white" style={{ height: "90vh", width: "95vw" }}>
            <Navbar />
            <div>
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/lookup-history" element={<LooqupHistory />} />
                <Route path="/:chatId" element={<ChatID />} />
                <Route path="/account-settings" element={<AccountSettings />} />
              </Routes>
            </div>
          </div>
          {/* <Navbar /> */}
          {/* <div>
            <Routes>
              <Route path="/" element={<Home />} />
          
          <Route path="/chats" element={<Chats />} />
          <Route path="/new-chat" element={<NewChat />} />
        </Routes>
      </div> */}
        </div>
      </Router >
    </ChatProvider >
  );
}

export default App; 
