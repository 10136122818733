import { useState, useEffect, useRef } from "react";


const TypingEffect = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");
  const typingSpeed = 50; // Adjust typing speed (ms) per character


  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayedText((prev) => prev + text[index]);
      index++;
      if (index >= text.length) {
        clearInterval(intervalId); // Clear interval when the text is fully displayed
      }
    }, typingSpeed);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [text]);

  return <p>{displayedText}</p>;
};

    
  
const Accordion = ({
  title,
  logsContent,
  toolContent,
  isOpen,
  onToggle,
  messagesEndRef,
}) => {
  return (
    <div>
      <button
        type="button"
        onClick={onToggle}
        className="flex items-start justify-between w-full py-5 font-medium text-gray-500 "
      >
        <div>
          <strong>{title}</strong> | Search:<span>{toolContent}</span>
        </div>

        <svg
          className={`w-3 h-3 ${isOpen ? "" : "rotate-180"}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="py-5">
          <div className="mb-4 text-white">{logsContent}</div>
          <div ref={messagesEndRef} />
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

const Thinking = ({ log, SourcesData, messagesEndRef }) => {
  const [logData, setLogData] = useState([]);
  const [openIndex, setOpenIndex] = useState(0);
  const prevLogRef = useRef([]);

  useEffect(() => {
    if (!log.length) return;
    const newLog = log[0]; // Assuming we're dealing with the first log entry in the array

    // Function to check if a log already exists in the logData
    const isLogNew = (logEntry) => {
      return !prevLogRef.current.some(
        (existingLog) =>
          JSON.stringify(existingLog) === JSON.stringify(logEntry)
      );
    };

    // Function to safely parse JSON
    const safeParseJSON = (jsonString) => {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        console.error("Failed to parse JSON:", e);
        return null; // Return null or any default value if parsing fails
      }
    };

    // Check if the new log is different from all stored logs
    if (isLogNew(newLog)) {
      const data = safeParseJSON(newLog.tool_input);

      setLogData((prevLogs) => [...prevLogs, { ...newLog, tool_input: data }]);

      // Update the reference to the latest logData
      prevLogRef.current = [...prevLogRef.current, newLog];
    }
  }, [log]);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return logData.map((entry, index) => {
    const { tool, tool_input, log, type } = entry;

    if (type === "AgentAction" && tool_input) {
      SourcesData(tool.split("Tool")[0]);
      return (
        <div className="w-full">
          <Accordion
            key={index}
            title={tool.split("Tool")[0]}
            logsContent={
              <>
                <div>
                  <p>{log.split("Action:")[0]}</p>
                </div>
              </>
            }
            toolContent={tool_input.query}
            isOpen={openIndex === index}
            messagesEndRef={messagesEndRef}
            onToggle={() => handleToggle(index)}
          />
        </div>
      );
    }

    return null;
  });
};

export default Thinking;
